<template>
  <v-btn
    plain=""
    icon
    class="mr-n1"
    min-width="0"
    text
    to="/"
    exact
  >
    <v-badge
      v-if="acikSiparis > 0"
      overlap
      :content="acikSiparis"
      color="accent"
    >
      <v-icon>mdi-cart</v-icon>
    </v-badge>
    <v-icon v-else>
      mdi-cart
    </v-icon>
  </v-btn>
</template>

<script>
  import { veri, depo } from './../../../db'

  export default {
    name: 'DefaultGoHome',
    data: () => ({
      acikSiparis: 0,
    }),
    mounted () {
      veri
        .ref('siparisler')
        .orderByChild('durum')
        .equalTo(0)
        .on('value', event => {
          if (event.exists()) {
            Object.size = function (obj) {
              var size = 0
              var key
              for (key in obj) {
                if (obj.hasOwnProperty(key)) size++
              }
              return size
            }
            this.acikSiparis = Object.size(event.val())
          } else {
            this.acikSiparis = 0
          }
        })
    },
  }
</script>
